<template>
    <svg
        width="43"
        height="43"
        viewBox="0 0 43 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="play-cursor v2"
    >
        <circle
            opacity="0.8"
            cx="21.0007"
            cy="21.0007"
            r="21.0007"
            fill="#CD7300"
        />
        <path
            d="M29.0488 19.2676C30.3822 20.0374 30.3822 21.9619 29.0488 22.7317L18.478 28.8347C17.1447 29.6045 15.478 28.6423 15.478 27.1027L15.478 14.8965C15.478 13.3569 17.1447 12.3947 18.478 13.1645L29.0488 19.2676Z"
            fill="white"
        />
    </svg>
</template>
